import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
// import AddNearMatch from './addNearMatch'
import RunRequest from '../../../../request/submittedRequest/runRequest';
import itcDataUpdation from '../itcDataUpdation';
export default {
  name: '',
  watch: {
    currentPage: function() {
      this.getGstNearMatchDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGstNearMatchDetails();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
  },
  components: {
    // AddNearMatch
    RunRequest,
    itcDataUpdation
  },
  props: {},
  data() {
    return {
      payload: null,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      loader: false,
      showValueSetModal: false,
      setTimeVsetCode: null,
      valueSetName: null,
      parent_value_set_id: null,
      nearMatchRowDetail: null,
      viewFlag: false,
      percentage: null,
      legalEntity: {
        value: null,
        text: null
      },
      gstNumber: {
        value: null,
        text: null
      },
      periodFrom: {
        value: null,
        text: null
      },
      periodTo: {
        value: null,
        text: null
      },
      source: {
        value: null,
        text: null
      },
      periodType: null,
      // itcType: { value: null, text: null },
      defaultValue: { value: null, text: null },
      selectAllCheckBox: false,
      fpGstnMatch: null,
      tpGstnMatch: null,
      rateMatch: null,
      invoiceNoMatch: null,
      taxAmountMatch: null,
      invoiceDateMatch: null,
      // showAddNearMatchModal: false,
      nearMatchdata: [],
      nearMatchFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'fpgstn_2b',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'fp_gstn',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'TPGST_2B',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'tp_gstn',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'INV_NO_2B',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'invoice_num',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'DATE_2B',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'invoice_date',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'TAXABLE_AMT_2B',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'taxable_value',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'TOTAL_TAX_2B',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'TOTAL_TAX_PR',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'IGST_AMT_2B',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'igst',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'CGST_AMT_2B',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'cgst',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'SGST_AMT_2B',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'sgst',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'TAX_RATE_PR',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'match_score',
          label: 'Matching Percentage',
          variant: 'success',
          class: 'text-center'
        },
        {
          key: 'VENDOR_NAME_2B',
          label: 'GSTR 2B',
          class: 'text-center'
        },
        {
          key: 'vendor_name',
          label: 'ERP',
          variant: 'success',
          class: 'text-center'
        }
      ],
      fpMatchValue: 'all',
      tpMatchValue: 'all',
      rateMatchValue: 'all',
      invNoMatchValue: 'all',
      taxMatchValue: 'all',
      invDateMatchValue: 'all',
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      userName: null,
      invoiceno: null,
      vendorName: null,
      downloadPayload: null,
      matchScore: 0,
      date: 0,
      matchScoreOptions: [],
      dateOptions: [],
      showRunRequestModal: false,
      reqRunNearMatch: {
        legalEntity: {
          value: null,
          text: null
        },
        gstNumber: {
          value: null,
          text: null
        },
        periodFrom: {
          value: null,
          text: null
        },
        periodTo: {
          value: null,
          text: null
        }
        // itcType: {
        //   value: null,
        //   text: null
        // }
      },
      showItcUpdationModal: false
    };
  },
  validations: {
    legalEntity: {
      value: {
        required
      }
    },
    // itcType: {
    //   value: {
    //     required
    //   }
    // },
    periodFrom: {
      value: { required }
    },
    periodTo: {
      value: { required }
    },
    gstNumber: {
      value: { required }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          // this.showAddNearMatchModal = true;
          // this.nearMatchRowDetail = null;
        } else if (actionName == 'download') {
          this.downloadExcelData();
        } else if (
          actionName == 'save' &&
          !this.showItcUpdationModal &&
          !this.showRunRequestModal
        ) {
          this.updateNearMatch();
        }
      }
    });

    for (let i = 0; i <= 100; i++) {
      this.matchScoreOptions.push({
        value: i,
        text: i.toString()
      });
    }

    for (let i = 0; i <= 32; i++) {
      if (i == 0) {
        this.dateOptions.push({
          value: i,
          // text: i.toString()
          text: 'Today'
        });
      } else if (i == 1) {
        this.dateOptions.push({
          value: i,
          text: 'Within ' + i + ' day'
        });
      } else if (i == 32) {
        this.dateOptions.push({
          value: 'all',
          text: 'All'
        });
      } else {
        this.dateOptions.push({
          value: i,
          text: 'Within ' + i + ' days'
        });
      }
    }
  },
  methods: {
    nearMatchUpdationForm() {
      this.reqRunNearMatch = {
        legalEntity: {
          text: this.legalEntity.text,
          value: this.legalEntity.value
        },
        gstNumber: {
          text: this.gstNumber.text,
          value: this.gstNumber.value
        },
        periodFrom: {
          text: this.periodFrom.text,
          value: this.periodFrom.value
        },
        periodTo: {
          text: this.periodTo.text,
          value: this.periodTo.value
        }
        // itcType: {
        //   text: this.itcType.text,
        //   value: this.itcType.value
        // }
      };
      this.showItcUpdationModal = true;
    },
    hideItcUpdationModal(flag) {
      this.showItcUpdationModal = flag;
    },
    downloadExcelData() {
      this.loader = true;
      const downloadpayload = { ...this.downloadPayload };
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        'itcReconciliation/getGstNearMatchDetails',
        'itc-reconciliation',
        () => (this.loader = false)
      );
    },
    viewMore() {
      this.viewFlag = !this.viewFlag;
    },
    fpGstn(fpGstnMatch) {
      if (fpGstnMatch === '0') {
        this.fpMatchValue = 'unmatch';
      } else if (fpGstnMatch === '1') {
        this.fpMatchValue = 'all';
      } else if (fpGstnMatch === '2') {
        this.fpMatchValue = 'match';
      } else {
        this.fpMatchValue = null;
      }
    },
    tpGstn(tpGstnMatch) {
      if (tpGstnMatch === '0') {
        this.tpMatchValue = 'unmatch';
      } else if (tpGstnMatch === '1') {
        this.tpMatchValue = 'all';
      } else if (tpGstnMatch === '2') {
        this.tpMatchValue = 'match';
      } else {
        this.tpMatchValue = null;
      }
    },
    rate(rateMatch) {
      if (rateMatch === '0') {
        this.rateMatchValue = 'unmatch';
      } else if (rateMatch === '1') {
        this.rateMatchValue = 'all';
      } else if (rateMatch === '2') {
        this.rateMatchValue = 'match';
      } else {
        this.rateMatchValue = null;
      }
    },
    invoiceNo(invoiceNoMatch) {
      if (invoiceNoMatch === '0') {
        this.invNoMatchValue = 'unmatch';
      } else if (invoiceNoMatch === '1') {
        this.invNoMatchValue = 'all';
      } else if (invoiceNoMatch === '2') {
        this.invNoMatchValue = 'match';
      } else {
        this.invNoMatchValue = null;
      }
    },
    taxAmount(taxAmountMatch) {
      if (taxAmountMatch === '0') {
        this.taxMatchValue = 'unmatch';
      } else if (taxAmountMatch === '1') {
        this.taxMatchValue = 'all';
      } else if (taxAmountMatch === '2') {
        this.taxMatchValue = 'match';
      } else {
        this.taxMatchValue = null;
      }
    },
    invoiceDate(invoiceDateMatch) {
      if (invoiceDateMatch === '0') {
        this.invDateMatchValue = 'unmatch';
      } else if (invoiceDateMatch === '1') {
        this.invDateMatchValue = 'all';
      } else if (invoiceDateMatch === '2') {
        this.invDateMatchValue = 'match';
      } else {
        this.invDateMatchValue = null;
      }
    },
    searchNearMatchData() {
      if (this.date === 'all') {
        this.getGstNearMatchDetailsAll();
      } else {
        this.getGstNearMatchDetails();
      }
    },
    getGstNearMatchDetails() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.legalEntity.value,
          // itc_type: this.itcType.value,
          period_from: this.periodFrom.value,
          period_to: this.periodTo.value,
          inv_amount_diff: this.taxMatchValue,
          gst_number: this.gstNumber.value,
          // fp_gst: this.fpMatchValue,
          tp_gstn_diff: this.tpMatchValue,
          // rate_gst: this.rateMatchValue,
          inv_num_diff: this.invNoMatchValue,
          inv_date_diff: this.invDateMatchValue,
          source: this.source.value,
          gstr2_user_name: this.userName,
          gstr2_invoice_number: this.invoiceno,
          gstr2_vendor_name: this.vendorName,
          matchscore: this.matchScore,
          date: this.date
        };
        this.loader = true;
        this.downloadPayload = payload;
        this.$store
          .dispatch('itcReconciliation/getGstNearMatchDetails', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.nearMatchdata = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getGstNearMatchDetailsAll() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.legalEntity.value,
          period_from: this.periodFrom.value,
          period_to: this.periodTo.value,
          inv_amount_diff: this.taxMatchValue,
          gst_number: this.gstNumber.value,
          tp_gstn_diff: this.tpMatchValue,
          inv_num_diff: this.invNoMatchValue,
          inv_date_diff: this.invDateMatchValue,
          source: this.source.value,
          gstr2_user_name: this.userName,
          gstr2_invoice_number: this.invoiceno,
          gstr2_vendor_name: this.vendorName,
          matchscore: this.matchScore
        };
        this.loader = true;
        this.downloadPayload = payload;
        this.$store
          .dispatch('itcReconciliation/getGstNearMatchDetailsAll', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.nearMatchdata = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    clearSearch() {
      this.legalEntity = this.defaultValue;
      this.gstNumber = this.defaultValue;
      this.periodFrom = this.defaultValue;
      this.periodTo = this.defaultValue;
      // this.itcType = this.defaultValue;
      this.source = this.defaultValue;
      this.userName = null;
      this.invoiceno = null;
      this.vendorName = null;
      this.matchScore = null;
      this.date = null;
    },
    rowSelected(item) {
      this.nearMatchRowDetail = item;
      // this.showHideNearMatchModal(true);
    },
    // showHideNearMatchModal(flag) {
    //    this.showAddNearMatchModal = flag;
    // },
    checkUncheckAll() {
      this.nearMatchdata = this.nearMatchdata.map((data, index) => {
        data.selectBox = this.selectAllCheckBox;
        if (this.selectAllCheckBox) {
          this.selectBoxChecked(data.selectBox, index);
        }
        return data;
      });
    },
    selectBoxChecked(flag, index) {
      this.nearMatchdata[index].select = flag;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.gstNumber = this.defaultValue;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.gstNumber = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SOURCE) {
        this.source = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (this.periodType === 'periodFrom') {
          this.periodFrom = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        } else if (this.periodType === 'periodTo') {
          this.periodTo = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        }
      }
      //  else if (this.vsetCode === appStrings.VALUESETTYPE.ITC_TYPE_2A_2B) {
      //   this.itcType = {
      //     value: item.value_code,
      //     text: item.value_meaning
      //   };
      // }
    },
    openValueSetModal(vsetCode, periodType) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.periodType = periodType;
      this.showValueSetModal = true;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD ||
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, periodType) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
        this.gstNumber = this.defaultValue;
        this.periodFrom = this.defaultValue;
        this.periodTo = this.defaultValue;
      } else if (vsetCode === this.gstNumber.value) {
        this.gstNumber = this.defaultValue;
      } else if (vsetCode === 'GL_PERIOD' && periodType === 'periodFrom') {
        this.periodFrom = this.defaultValue;
      } else if (vsetCode === 'GL_PERIOD' && periodType === 'periodTo') {
        this.periodTo = this.defaultValue;
        // } else if (vsetCode === this.itcType.value) {
        //   this.itcType = this.defaultValue;
      } else if (vsetCode === this.source.value) {
        this.source = this.defaultValue;
      } else if (vsetCode === this.matchScore) {
        this.matchScore = 0;
      } else if (vsetCode === this.date) {
        this.date = 0;
      }
    },
    updateNearMatch() {
      const filterData = this.nearMatchdata.filter(key => key.select);
      if (filterData.length) {
        const nearMatchDetails = filterData.map(elem => {
          return {
            le_id: elem.le_id,
            reference_id: elem.reference_id,
            // reference_line_id: elem.reference_line_id,
            // reference_type: elem.reference_type,
            fp_gst_2b: elem.fpgstn_2b,
            tp_gst_2b: elem.TPGST_2B,
            invoice_date_2b: elem.DATE_2B,
            invoice_number_2b: elem.INV_NO_2B,
            rate_2b: elem.TAX_RATE_PR
          };
        });
        const payload = {
          data: nearMatchDetails ? nearMatchDetails : null
        };
        this.loader = true;
        this.$store
          .dispatch('itcReconciliation/updateNearMatch', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 201) {
              this.$bvToast.toast(resp.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
              // this.isSuccess = true;
              // this.showAlert = true;
              // this.responseMsg = resp.data.message;
              this.nearMatchdata = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
              this.getGstNearMatchDetails();
            } else {
              // this.showAlert = true;
              // this.isSuccess = false;
              // this.responseMsg = resp.response.data.message;
              this.$bvToast.toast(resp.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        this.$bvToast.toast('Kindly select invoices first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    runRequest() {
      this.reqRunNearMatch = {
        legalEntity: {
          text: this.legalEntity.text,
          value: this.legalEntity.value
        },
        gstNumber: {
          text: this.gstNumber.text,
          value: this.gstNumber.value
        },
        periodFrom: {
          text: this.periodFrom.text,
          value: this.periodFrom.value
        },
        periodTo: {
          text: this.periodTo.text,
          value: this.periodTo.value
        },
        itcType: {
          text: '2B',
          value: '2B'
        }
      };
      this.showRunRequestModal = true;
    },
    resetModal() {
      this.showRunRequestModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
